import { useState, useEffect } from "react";
import axios from "axios";
import { MAX_CLIENT_LENGTH, MIN_CLIENT_LENGTH } from "@icarius-utils/constants";
import { changeTheme } from "../actions";
import { setDevExtremeTheme } from "@icarius-utils/theme";
import { isKeyPresent, getValueFromKey, setKeyValue } from "@icarius-utils/localStorage";
import { CLIENT_CONFIG_ENDPOINT, IMAGES_ENDPOINT_NO_STATIC } from "@icarius-connection/endpoints";
import { CLIENT } from "@icarius-utils/properties";
import { useDispatch } from "react-redux";

const useGetClientTheme = () => {

  const [count, setCount] = useState(0);
  const [client, setClient] = useState(CLIENT || (isKeyPresent("icarius-client") ? getValueFromKey("icarius-client") : ""));
  const dispatch = useDispatch();

  const changeThemeClient = (newClient) => {
    setClient(newClient);
  }

  useEffect(() => {
    const setThemeInitialTheme = (themeObject) => {
      dispatch(changeTheme(themeObject))
        .then(() => {
          document.documentElement.setAttribute('data-theme', themeObject.theme);
          setDevExtremeTheme(themeObject.theme);
          setKeyValue("icarius-theme", themeObject.theme);
        })
    }

    const getTheme = () => {
      const configClient = client ? client.toUpperCase() : "default";
      const themeFromLocalStorage = isKeyPresent("icarius-theme") ? getValueFromKey("icarius-theme") : 'dark';

      axios.get(`${CLIENT_CONFIG_ENDPOINT}${configClient}.json`)
        .then((resp) => {
          setKeyValue("icarius-login-background", `${IMAGES_ENDPOINT_NO_STATIC}${resp.data.path_login_image}`);
          setThemeInitialTheme({ ...resp.data, theme: themeFromLocalStorage || resp.data.theme });
        })
        .catch(() => {
          setThemeInitialTheme({ theme: themeFromLocalStorage });
        });
    }

    if (client && client.length >= MIN_CLIENT_LENGTH && client.length <= MAX_CLIENT_LENGTH) {
      getTheme();
    }
  }, [count, client, dispatch])

  const resetClientTheme = () => {
    setCount(prev => prev + 1);
  };

  return { count, changeThemeClient, resetClientTheme };
}

export default useGetClientTheme;
