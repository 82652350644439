import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { GeoanalysisDefinitionIcon, KpiDefinitionIcon, QueryDefinitionIcon } from "@icarius-icons/";
import { getLocalizedString } from "@icarius-localization/strings";

const ManagementDefinition = () => {

  const data = [
    {
      name: "Definición de consultas",
      icon: <QueryDefinitionIcon />,
      path: paths.queryDefinition,
    },
    {
      name: "Definición de KPI",
      icon: <KpiDefinitionIcon />,
      path: paths.kpiDefinition,
    },
    {
      name: "Consultas para geoanálisis",
      icon: <GeoanalysisDefinitionIcon />,
      path: paths.geoQuery,
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("managementDefinition")}
      data={data}
    />
  );
}

export default ManagementDefinition;
