import NAME from "./constants";

export const getRequestingVersion = store => store[NAME].requestingVersion;
export const getVersion = store => store[NAME].version;
export const getRequestingLogin = store => store[NAME].requestingLogin || store[NAME].forgotPasswordIsLoading || store[NAME].resetPasswordIsLoading;
export const getLoginResponse = store => store[NAME].loginResponse;
export const getSocieties = store => store[NAME].societies;
export const getIsAuthorized = store => store[NAME].loginResponse && store[NAME].societySelected;
export const getSocietySelected = store => store[NAME].societySelected;
export const getTheme = store => store[NAME].theme;
export const getHasToChangePin = store => store[NAME].hasToChangePin;