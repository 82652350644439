import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetPasswordAction } from "../actions";

const useCheckRegenPin = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const regenPinCode = params.get("regenPinCode");
    
    if (regenPinCode) {
      dispatch(resetPasswordAction(regenPinCode));
    }
  }, [dispatch])
}

export default useCheckRegenPin;
