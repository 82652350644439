import NAME from "./constants";

export const REQUEST_LOGIN = NAME + "/REQUEST_LOGIN";
export const REQUEST_LOGIN_FULFILLED = NAME + "/REQUEST_LOGIN_FULFILLED";
export const REQUEST_LOGIN_REJECTED = NAME + "/REQUEST_LOGIN_REJECTED";

export const REQUEST_VERSION = NAME + "/REQUEST_VERSION";
export const REQUEST_VERSION_FULFILLED = NAME + "/REQUEST_VERSION_FULFILLED";

export const SELECT_SOCIETY = NAME + "/SELECT_SOCIETY";

export const LOGOUT = NAME + "/LOGOUT";

export const ASK_FOR_NEW_PASSWORD = NAME + "/ASK_FOR_NEW_PASSWORD";
export const ASK_FOR_NEW_PASSWORD_FULFILLED = NAME + "/ASK_FOR_NEW_PASSWORD_FULFILLED";

export const FORGOT_PASSWORD = NAME + "/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FULFILLED = NAME + "/FORGOT_PASSWORD_FULFILLED";
export const FORGOT_PASSWORD_REJECTED = NAME + "/FORGOT_PASSWORD_REJECTED";

export const CHANGE_THEME = NAME + "/CHANGE_THEME";

export const RESET_PASSWORD = NAME + "/RESET_PASSWORD";
export const RESET_PASSWORD_FULFILLED = NAME + "/RESET_PASSWORD_FULFILLED";
export const RESET_PASSWORD_REJECTED = NAME + "/RESET_PASSWORD_REJECTED";

export const SET_SOCIETIES_WITH_GETDATA = NAME + "/SET_SOCIETIES_WITH_GETDATA";

export const GET_SOCIETIES = NAME + "/GET_SOCIETIES";
export const GET_SOCIETIES_FULFILLED = NAME + "/GET_SOCIETIES_FULFILLED";
export const GET_SOCIETIES_REJECTED = NAME + "/GET_SOCIETIES_REJECTED";