import React, { useState, useEffect, useCallback } from "react";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { USER } from "@icarius-utils/properties";
import { useDispatch } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { ASK_FOR_NEW_PASSWORD } from "../actionTypes";

const SocietySelectDialog = (props) => {
  const [societyKey, setSocietyKey] = useState("")
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const {
    open,
    options,
    actualSociety,
    handleConfirm,
    handleCancelDialog,
    handleCloseDialog,
  } = props;

  const handleSubmit = useCallback(() => {
    if (!societyKey) {
      setError(getLocalizedErrorString("selectSociety"));
      return;
    }

    const society = options.find(item => item.key === societyKey);
    if (society.regenPINActive) {
      dispatch(
        openDialogAction({
          title: 'Atención',
          msg: 'No es posible acceder porque tiene una recuperación de su PIN en trámite.',
          acceptOnly: true,
          onConfirm: () => handleCancelDialog(),
        })
      );
      return;
    }

    if (society.regenPINExpired) {
      dispatch(
        openDialogAction({
          title: 'Atención',
          msg: 'No es posible acceder porque la recuperación de su PIN ha expirado. Por favor, recupere su PIN nuevamente.',
          acceptOnly: true,
          onConfirm: () => handleCancelDialog(),
        })
      );
      return;
    }

    if (society.blocked) {
      dispatch(
        openDialogAction({
          title: 'Atención',
          msg: getLocalizedErrorString("tooManyLoginRequests").replace("{minutos}", society.remainingMinutes),
          acceptOnly: true,
          onConfirm: () => handleCancelDialog(),
        })
      );
      return;
    }

    if (society.shouldUserRenewPin) {
      dispatch({ type: ASK_FOR_NEW_PASSWORD });
      handleCloseDialog();
      return;
    }

    handleConfirm(societyKey);
    handleCloseDialog();
  }, [dispatch, options, societyKey, handleCancelDialog, handleCloseDialog, handleConfirm]);

  useEffect(() => {
    if (USER) { // Acceso automatizado para DEV
      const icariusChile = options.find((item) => item.value === "Delta Soft Chile S.A.");
      if (icariusChile) {
        handleConfirm(icariusChile.key);
      }
    }

    if (options?.length === 1) {
      setSocietyKey(options[0].key);
      handleSubmit();
    }
  }, [options, handleConfirm, handleSubmit])

  const handleSocietyChange = (e) => {
    setSocietyKey(e.target.value)
    setError(null);
  }

  const handleCancel = () => {
    setError("");
    setSocietyKey("");
    handleCancelDialog();
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("selectSocietyTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <FormControl>
            <InputLabel>{getLocalizedString("society")}</InputLabel>
            <Select
              name={"society"}
              label={getLocalizedString("society")}
              onChange={handleSocietyChange}
              value={societyKey}
              className="custom-input"
            >
              {
                options.map(option =>
                  <MenuItem
                    className={"whiteText"}
                    value={option.key}
                    key={option.key}
                    disabled={actualSociety ? option.key === actualSociety.number : false}>
                    {option.value}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
          {
            Boolean(error) &&
            <Typography variant={"subtitle1"} className={"error-text"}>
              {error}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleCancel} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default SocietySelectDialog;
