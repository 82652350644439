import * as actionTypes from "./actionTypes";
import { openDialogAction, openErrorDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { roleNames } from "@icarius-utils/constants";
import { resetAppState } from "src/app/actions";
import { getVersion as getStoredVersion } from "./selectors";
import { removeAuthInfo, setAuthInfo, getAuthInfo, isAuthInfoPresent } from "./sessionStorage";
import {
  getVersionAPI,
  loginAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  logoutAPI,
  getSocietiesAPI,
} from "@icarius-connection/api";
import { setKeyValue } from "@icarius-utils/localStorage";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const RE_FETCH_TIMEOUT = 5000;

export const changeTheme = (config) => async (dispatch) => {
  config.theme = config.theme.toLowerCase();
  dispatch({ type: actionTypes.CHANGE_THEME, payload: config });

  return config;
};

export const getVersionAction = async (dispatch, getState) => {
  if (getStoredVersion(getState())) return;

  dispatch({ type: actionTypes.REQUEST_VERSION });
  try {
    let response = await getVersionAPI();
    dispatch({
      type: actionTypes.REQUEST_VERSION_FULFILLED,
      payload: response.data && response.data.version,
    });
    return response;
  } catch (e) {
    setTimeout(() => dispatch(getVersionAction), RE_FETCH_TIMEOUT);
  }
};

export const loginAction = (role, client, user, pin) => async (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_LOGIN });

  try {
    const upperCaseClient = client.toUpperCase();
    let response = await loginAPI({
      client: upperCaseClient,
      user: user,
      password: pin,
      level: role,
      app: "web",
    });

    dispatch({
      type: actionTypes.REQUEST_LOGIN_FULFILLED,
      payload: response.data,
    });

    if (response.data && response.data.token) setAuthInfo({ token: response.data.token });
    if (response.data) setKeyValue("icarius-client", upperCaseClient);

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.REQUEST_LOGIN_REJECTED, payload: e });
    dispatch(
      openErrorDialogAction({
        msg: getLocalizedErrorString("loginError").replace("{role}", roleNames[role]),
        acceptOnly: true,
      })
    );
  }
};

export const selectSociety = (society) => async (dispatch) => {
  dispatch({ type: actionTypes.SELECT_SOCIETY, payload: society });
}

export const logOutAction = (dispatch) => {
  dispatch(resetAppState);
  dispatch({ type: actionTypes.LOGOUT });
};

export const killTokens = () => async () => {
  try {
    if (isAuthInfoPresent()) {
      let response = await logoutAPI({ data_token: getAuthInfo().token });
      removeAuthInfo();

      return response;
    }
  } catch (e) {
  }
};

export const forgotPasswordAction = (user, client, society) => async dispatch => {
  dispatch({ type: actionTypes.FORGOT_PASSWORD });
  try {
    let response = await forgotPasswordAPI({ society, user, client: client.toUpperCase() });
    dispatch({ type: actionTypes.FORGOT_PASSWORD_FULFILLED, payload: response.data });
    dispatch(openDialogAction({ msg: getLocalizedString("mailSent"), acceptOnly: true }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_REJECTED, payload: e });
    dispatch(openErrorDialogAction(e));
  }
};

// este manda mail al usuario con el pin provisorio
export const resetPasswordAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.RESET_PASSWORD });
  try {
    let response = await resetPasswordAPI({ code });
    dispatch({ type: actionTypes.RESET_PASSWORD_FULFILLED });

    if (response.data.status === "OK") {
      dispatch(
        openDialogAction({
          msg: getLocalizedString("pinRegenerationConfirmed"),
          acceptOnly: true,
        })
      );
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.RESET_PASSWORD_REJECTED });
  }
};

export const getSocietiesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SOCIETIES });
  try {
    let response = await getSocietiesAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_SOCIETIES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_SOCIETIES_FULFILLED,
      payload: { societies: data },
    });
    return response;
  } catch (e) {
    if (e.response.data.status === "RECOVERY_ON_COURSE") {
      dispatch({ type: actionTypes.GET_SOCIETIES_REJECTED });
      dispatch(
        openDialogAction({
          msg: 'Ya tiene una recuperación de su PIN en trámite.',
          acceptOnly: true,
        })
      );
      return;
    }
    dispatch({ type: actionTypes.GET_SOCIETIES_REJECTED, payload: e });
  }
};