/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { Typography, Button, IconButton, CircularProgress, Grid } from "@material-ui/core";
import { getLocalizedErrorString, getLocalizedString, setLanguage } from "@icarius-localization/strings";
import {
  getSocietiesAction,
  getVersionAction,
  logOutAction,
  loginAction,
  forgotPasswordAction,
  selectSociety,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getHasToChangePin,
  getLoginResponse,
  getSocieties,
  getVersion,
  getRequestingVersion,
  getTheme,
} from "../selectors";
import CustomTextField from "@icarius-common/input/textField";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import { openDialogAction, openErrorDialogAction } from "@icarius-common/dialog/actions";
import SocietySelectDialog from "./societySelectDialog";
import {
  roles,
  MAX_CLIENT_LENGTH,
  MAX_PIN_LENGTH,
  MAX_USER_LENGTH,
  MIN_CLIENT_LENGTH,
  MIN_PIN_LENGTH,
  MIN_USER_LENGTH,
} from "@icarius-utils/constants";
import ChangePinDialog from "@icarius-common/changePinDialog";
import IcariusLogo from "@icarius-assets/images/icariusLogo";
import { PlayCircleOutlineIcon } from "@icarius-icons";
import { isKeyPresent, getValueFromKey, setKeyValue } from "@icarius-utils/localStorage";
import { USER, PIN, CLIENT } from "@icarius-utils/properties";

const VideoItem = ({ title, onClick }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }} onClick={onClick}>
    <IconButton size={"small"} disableRipple style={{ marginRight: 2 }}>
      <PlayCircleOutlineIcon className="whiteText" fontSize="small" />
    </IconButton>
    <Typography variant="subtitle1" className="help-pin">
      {title}
    </Typography>
  </div>
)

const LoginPanel = (props) => {

  const {
    handleClientThemeChange,
    resetAll,
    clientCode,
    userCode,
  } = props;

  const dispatch = useDispatch();
  const [loginSocietyDialogIsOpen, setLoginPinSocietyDialogIsOpen] = useState(false);
  const [resetPinSocietyDialogIsOpen, setResetPinSocietyDialogIsOpen] = useState(false);
  const [client, setClient] = useState(clientCode || CLIENT || ((isKeyPresent("icarius-client") ? getValueFromKey("icarius-client") : "")));
  const [user, setUser] = useState(userCode || USER || "");
  const [pin, setPin] = useState(PIN || "");

  const theme = useSelector(getTheme);
  const requestingVersionValue = useSelector(getRequestingVersion);
  const version = useSelector(getVersion);
  const loginResponse = useSelector(getLoginResponse);
  const societies = useSelector(getSocieties);
  const hasToChangePin = useSelector(getHasToChangePin);

  const formattedSocieties = societies.map(society => ({
    ...society,
    key: society.number || society.key,
    value: society.name || society.value,
  }));

  const copyrightString = getLocalizedString("copyright").replace("{year}", new Date().getFullYear());
  const versionString = `${getLocalizedString("version")}: ${requestingVersionValue || !version ? "" : version.replace(",", ".")}`;
  const clientIsValid = Boolean(client) && client.length >= MIN_CLIENT_LENGTH && client.length <= MAX_CLIENT_LENGTH;
  const userIsValid = Boolean(user) && user.length >= MIN_USER_LENGTH && user.length <= MAX_USER_LENGTH;
  const pinIsValid = valueIsOfType(pin, valueTypes.pin) && pin.length >= MIN_PIN_LENGTH && pin.length <= MAX_PIN_LENGTH;

  useEffect(() => {
    setLanguage("es");
    setKeyValue("language", "es");
  }, [])

  useEffect(() => {
    if (!version) dispatch(getVersionAction);
  }, [dispatch, version])

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleClientThemeChange(e.target.value);
    }
  };

  const submitForm = (e) => {
    // De esta forma, se permite que el navegador guarde las credenciales y que React siga funcionando de forma correcta
    e.preventDefault();
  };

  const handleCloseSocietyDialog = () => {
    setLoginPinSocietyDialogIsOpen(false);
    setResetPinSocietyDialogIsOpen(false);
  }

  const handleCancelSocietyDialog = () => {
    dispatch(logOutAction);
    dispatch(getVersionAction);
    resetAll();
  };

  const handleLogin = (role) => {
    if (!role || !client || !user || !pin) return;
    dispatch(loginAction(role, client, user, pin))
      .then((response) => {
        //En caso de ocurrir un fallo con el PIN, elimina el mismo
        if (typeof response === "undefined") {
          setPin("");
          return;
        }
        setLoginPinSocietyDialogIsOpen(true);
      });
  };

  const handleVideoClick = (url) => {
    let dlAnchorElem = document.getElementById("downloadAnchorElem");
    dlAnchorElem.setAttribute("href", url);
    dlAnchorElem.setAttribute("target", "_blank");
    dlAnchorElem.click();
  }

  const handleRecoverPinClick = () => {
    if (!client || !user) {
      dispatch(openErrorDialogAction({ msg: getLocalizedErrorString("invalidInfo") }));
      return;
    }

    dispatch(getSocietiesAction({ client, user }))
      .then((res) => {
        if (res?.status === 200) {
          setResetPinSocietyDialogIsOpen(true);
        }
      })
  };

  const changePin = (societyKey) => {
    const society = formattedSocieties.find(society => society.key === societyKey);

    dispatch(
      openDialogAction({
        title: getLocalizedString("pinWillChange"),
        msg: getLocalizedString("recoverPinDialogTextFull").replace("{user}", user).replace("{client}", client).replace("{society}", society.value),
        onConfirm: () => dispatch(forgotPasswordAction(user, client, societyKey)),
      })
    );
  }

  const handleConfirmSociety = (societyKey) => {
    const societyToDispatch = societies.find(society => society.number === societyKey);
    dispatch(selectSociety(societyToDispatch));
  };

  return (
    <section id={"left-panel"}>
      <form autoComplete="on" onSubmit={submitForm}>
        <div style={{ marginBottom: 30 }}>
          <IcariusLogo useLoginVersion theme={theme.theme} height={100} />
        </div>
        <CustomTextField
          isValid={clientIsValid}
          label={getLocalizedString("client")}
          value={client}
          type={"text"}
          isClient
          showState
          autoComplete
          onChange={value => setClient(value.toUpperCase())}
          onBlur={value => handleClientThemeChange(value.toUpperCase() || client)}
          onKeyDown={handleKeyPress}
          login={true}
        />
        <div className={"center-input"}>
          <CustomTextField
            isValid={userIsValid}
            label={getLocalizedString("user")}
            value={user}
            autoComplete
            type={"text"}
            showState
            onChange={value => setUser(value.toUpperCase())}
            login={true}
          />
        </div>
        <CustomTextField
          isValid={pinIsValid}
          label={getLocalizedString("pin") + " (" + getLocalizedString("key") + ")"}
          value={pin}
          showState
          autoComplete
          type={"password"}
          onChange={value => setPin(value)}
          login={true}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            ['collaborator', 'manager', 'employer'].map((role, index) => {
              return (
                <div key={role} style={{ marginTop: index === 0 ? 10 : 0 }}>
                  <Button
                    variant={"contained"}
                    className={"login-button"}
                    type={"submit"}
                    disabled={requestingVersionValue}
                    onClick={() => handleLogin(roles[role])}
                  >
                    <Typography style={{ textTransform: "none" }} variant={"button"}>
                      {getLocalizedString("loginAs")} {getLocalizedString(role)}
                    </Typography>
                  </Button>
                </div>
              )
            })
          }
        </div>
        <Typography variant={"subtitle1"} className={"recover-pin"} onClick={handleRecoverPinClick}>
          {getLocalizedString("recoverPinAccessCode")}
        </Typography>
        <Grid container direction="row" justify="center" style={{ padding: "10px" }} alignItems="center">
          <VideoItem title={getLocalizedString("roleAccess")} onClick={() => handleVideoClick("https://www.youtube.com/embed/z7axul6fSuQ")} />
          <VideoItem title={getLocalizedString("assignPIN")} onClick={() => handleVideoClick("https://www.youtube.com/embed/2h4u802QuAE")} />
        </Grid>
        <Grid container direction="column">
          <Typography className={"version-text"}>{copyrightString}</Typography>
          <Typography className={"version-text"}>{getLocalizedString("allRightsReserved")}</Typography>
        </Grid>
        <Grid container orientation="row" justify={"center"} alignContent={"center"}>
          <Typography className={"version-text"} style={{ marginRight: 5 }}>{versionString}</Typography>
          {
            Boolean(requestingVersionValue) &&
            <CircularProgress size={20} className={"version-progress"} />
          }
        </Grid>
        {
          loginSocietyDialogIsOpen &&
          <SocietySelectDialog
            open={Boolean(loginResponse) && !hasToChangePin}
            handleCloseDialog={handleCloseSocietyDialog}
            handleCancelDialog={handleCancelSocietyDialog}
            handleConfirm={handleConfirmSociety}
            options={formattedSocieties}
          />
        }
        {
          resetPinSocietyDialogIsOpen &&
          <SocietySelectDialog
            open={resetPinSocietyDialogIsOpen}
            handleCloseDialog={handleCloseSocietyDialog}
            handleCancelDialog={handleCancelSocietyDialog}
            handleConfirm={changePin}
            options={formattedSocieties}
          />
        }
        {
          hasToChangePin &&
          <ChangePinDialog
            open={hasToChangePin}
            hideClose
          />
        }
        <a id="downloadAnchorElem" style={{ display: "none" }} />
      </form>
    </section>
  );
}

export default LoginPanel;
