import * as actionTypes from "./actionTypes";

const initialState = {
  requestingLogin: false,
  requestingVersion: false,
  version: null,
  loginResponse: null,
  societySelected: null,
  hasToChangePin: false,
  forgotPasswordIsLoading: false,
  theme: { theme: null },
  resetPasswordIsLoading: false,
  societies: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_LOGIN:
      return { ...state, requestingLogin: true };
    case actionTypes.REQUEST_LOGIN_FULFILLED:
      return {
        ...state,
        requestingLogin: false,
        loginResponse: action.payload,
        societies: action.payload.user?.societies || [],
      };
    case actionTypes.REQUEST_LOGIN_REJECTED:
      return { ...state, requestingLogin: false };

    case actionTypes.GET_SOCIETIES:
      return { ...state, requestingLogin: true };
    case actionTypes.GET_SOCIETIES_FULFILLED:
      return {
        ...state,
        requestingLogin: false,
        societies: action.payload.societies,
      };
    case actionTypes.GET_SOCIETIES_REJECTED:
      return { ...state, requestingLogin: false };

    case actionTypes.REQUEST_VERSION:
      return { ...state, requestingVersion: true };
    case actionTypes.REQUEST_VERSION_FULFILLED:
      return {
        ...state,
        requestingVersion: false,
        version: action.payload,
      };
    case actionTypes.SET_SOCIETIES_WITH_GETDATA:
      return { ...state, societies: action.payload };

    case actionTypes.FORGOT_PASSWORD:
      return { ...state, forgotPasswordIsLoading: true };
    case actionTypes.FORGOT_PASSWORD_FULFILLED:
      return { ...state, forgotPasswordIsLoading: false };
    case actionTypes.FORGOT_PASSWORD_REJECTED:
      return { ...state, forgotPasswordIsLoading: false };

    case actionTypes.RESET_PASSWORD:
      return { ...state, resetPasswordIsLoading: true };
    case actionTypes.RESET_PASSWORD_FULFILLED:
      return { ...state, resetPasswordIsLoading: false };
    case actionTypes.RESET_PASSWORD_REJECTED:
      return { ...state, resetPasswordIsLoading: false };

    case actionTypes.ASK_FOR_NEW_PASSWORD:
      return { ...state, hasToChangePin: true };
    case actionTypes.ASK_FOR_NEW_PASSWORD_FULFILLED:
      return { ...state, hasToChangePin: false };

    case actionTypes.SELECT_SOCIETY: {
      return { ...state, societySelected: action.payload };
    }
    case actionTypes.LOGOUT:
      return { ...initialState, loginResponse: null, societies: [] };

    case actionTypes.CHANGE_THEME:
      return { ...state, theme: action.payload };
    default:
      return state;
  }
}
