import React, { useEffect } from "react";
import LoginPanel from "./loginPanel";
import withAnimatedMount from "@icarius-common/animatedMount";
import Loader from "@icarius-common/loader";
import { getRequestingLogin } from "../selectors";
import { useSelector } from "react-redux";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Snackbar } from "@icarius-common/snackbar";
import useCheckRegenPin from "../hooks/useCheckRegenPin";
import useGetClientTheme from "../hooks/useGetClientTheme";
import { isKeyPresent, getValueFromKey } from "@icarius-utils/localStorage";
import DefaultBackground from "@icarius-assets/images/ICARIUS_LOGIN_2.jpeg";

const Login = () => {
  useCheckRegenPin();
  const { count, changeThemeClient, resetClientTheme } = useGetClientTheme();

  const hideBackgroundImage = useMediaQuery(`(max-width:1299px)`);

  const getBackgroundImageStyle = () => {
    if (isKeyPresent("icarius-login-background")) return { backgroundImage: ` url(${getValueFromKey("icarius-login-background")})` };
    return { backgroundImage: ` url(${DefaultBackground})` };
  }

  const loading = useSelector(getRequestingLogin);
  
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const clientCode = params.get("client");
  const userCode = params.get("user");

  useEffect(() => {
    document.title = 'ICARIUS';
  }, [])

  return (
    <section id={"login-page"}>
      <Loader open={loading} />
      <Grid container>
        {
          !hideBackgroundImage &&
          <Grid item md={7} lg={9}>
            <div id={"login-desktop-wallpaper"} style={getBackgroundImageStyle()} />
          </Grid>
        }
        <Grid item xs={12} sm={12} md={12} lg={hideBackgroundImage ? 12: 3}>
          <LoginPanel
            key={count}
            resetAll={resetClientTheme}
            clientCode={clientCode}
            userCode={userCode}
            handleClientThemeChange={client => changeThemeClient(client)}
          />
        </Grid>
      </Grid>
      <Snackbar />
    </section>
  );
}

export default withAnimatedMount(Login);
